import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

export default function Navigation() {
  const location = useLocation();
  return (
    <Navbar bg="dark" variant="dark" expand="md">
      <Container>
        <Navbar.Brand as={Link} to="/"><img src={process.env.PUBLIC_URL + '/logo192.png'} className="d-inline-block align-top nav-img" alt="" /> Kraina Warty - kajaki</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/" className={(location.pathname === "/") ? "active" : null}>Strona główna</Nav.Link>
            <Nav.Link as={Link} to="/zdjecia" className={(location.pathname === "/zdjecia") ? "active" : null}>Galeria zdjęć</Nav.Link>
            <Nav.Link as={Link} to="/cennik" className={(location.pathname === "/cennik") ? "active" : null}>Cennik</Nav.Link>
            <Nav.Link as={Link} to="/kontakt" className={(location.pathname === "/kontakt") ? "active" : null}>Kontakt</Nav.Link>
            <Nav.Link className='active' href="https://www.facebook.com/Kraina-Warty-kajaki-sp%C5%82ywy-wynajem-105021222162454" target="_blank"><i className="bi bi-facebook"></i></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
