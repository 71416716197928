import ImageGallery from 'react-image-gallery';
import './gallery.scss';

export default function Gallery() {
  let r = require.context('../img/gallery', false, /\.(png|jpe?g|svg)$/);
  let r2 = require.context('../img/gallery-thumb', false, /\.(png|jpe?g|svg)$/);
  const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
  let images = r.keys().sort(collator.compare);
  let gallery = [];
  images.map(item => gallery.push({ original: r(item), thumbnail: r2(item) }));
  return (
    <div className="page gallery">
      <h1>Galeria zdjęć</h1>
      <ImageGallery items={gallery} showIndex="true" />
    </div>
  );
}
