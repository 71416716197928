import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Navigation from './nav';
import AnimatedBg from './animatedBg';
import './index.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';


import Homepage from './pages/homepage';
import Gallery from './pages/gallery';
import Prices from './pages/prices';
import Contact from './pages/contact';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AnimatedBg>
      <BrowserRouter basename="/">
        <Navigation />
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route exact path="/zdjecia" element={<Gallery />} />
          <Route exact path="/cennik" element={<Prices />} />
          <Route exact path="/kontakt" element={<Contact />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
      <BottomBanner />
    </AnimatedBg>
  </React.StrictMode>
);

function PageNotFound() {
  return (
    <div className='page'>
      <center><h1><b>Błąd 404 - strona nie istnieje</b></h1></center>
    </div>
  );
}

function BottomBanner() {
  return (
    <div className='bottomBanner'>
      <p>Projekt i wykonanie strony:<br/>
      <a href='https://pies3nscy.eu/' target='_blank' rel="noreferrer">Pies3nscy</a> <a href='mailto:pies3nscy@gmail.com'>(pies3nscy@gmail.com)</a></p>
    </div>
  );
}