import bg from './background.mp4';
import './animatedBg.scss';

export default function AnimatedBg(p) {
    return (
        <>
            <video autoPlay muted loop id="animatedBg">
                <source src={bg} type="video/mp4" />
            </video>
            <div id="animatedBg-content">
                {p.children}
            </div>
        </>)
}