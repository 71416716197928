import Button from 'react-bootstrap/Button';
import './contact.scss';

export default function Contact() {
  const open = (a, b) => document.location.href = `${a}:${b}`;
  return (
    <div className="page contact">
      <h1>Kontakt</h1>
      <p>
        <b>Zaprzszamy do kontaktu telefonicznego i rezerwacji</b>
      </p>
      <div className='contact-buttons'>
        <Button variant="primary" className="button" onClick={() => open('tel', '603161393')}><i className="bi bi-telephone-fill"></i> 603 161 393</Button>
        <Button variant="primary" className="button" onClick={() => open('mailto', 'kajakikrainawarty@gmail.com')}><i className="bi bi-envelope-fill"></i> kajakikrainawarty@gmail.com</Button>
        <Button variant="primary" className="button"><i className="bi bi-geo-alt-fill"></i> Wolnica Niechmirowska 19</Button>
      </div>
      <div className='contact-map'>
        <iframe title="Mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4981.387602335944!2d18.755514262133804!3d51.371925540245876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x24a0e18b4736c9a9!2sKAJAKI%20-%20KRAINA%20WARTY!5e0!3m2!1spl!2spl!4v1657977660312!5m2!1spl!2spl" style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  );
}
