import Carousel from 'react-bootstrap/Carousel';
import './homepage.scss';

export default function Homepage() {
  let images = ["gallery/img1.jpg", "gallery/img5.jpg", "gallery/img31.jpg", "gallery/img3.jpg", "gallery/img12.jpg", "gallery/img27.jpg", "gallery/img21.jpg", "gallery/img20.jpg", "gallery/img32.jpg"];
  let imagesPath = images.map(i => require(`../img/${i}`))
  imagesPath.unshift(process.env.PUBLIC_URL + '/promotion-banner.jpg');
  return (
    <div className="page homepage">
      <h1>Strona główna</h1>
      <Carousel>
        {imagesPath.map((i) =>
          <Carousel.Item key={i} interval="3000">
            <img
              className="d-block carousel-img"
              src={i}
              alt=""
            />
          </Carousel.Item>
        )}
      </Carousel>
      <p>
        <b>Kajaki, spływy, wynajem.</b><br />
        REKREACYJNE TRASY KAJAKOWE nurtem rzeki Warty. Trasa WIELKI ŁUK - BURSZTYNOWY SZLAK obejmuje miejscowości: Działoszyn * Załęcze * Kamion * Krzeczów * Osjaków * Konopnica * Burzenin.<br />
        <b>NA TRASIE WIELE RÓŻNYCH ATRAKCJI!</b><br />Zapewniamy pełną obsługę naszych klientów łącznie z dowozem i odbiorem we wcześniej uzgodnionych miejscach. Klienci mają również możliwość wypożyczenia od nas sprzętu i zorganizowania sobie wypraw we własnym zakresie. Istnieje możliwość zbiórki w bazie w miejscowości KONOPNICA.<br />
        tel. 603 161 393<br />
        Zapraszamy i życzymy niezapomnianych przygód i wrażeń.</p>
    </div>
  );
}
