import './prices.scss';
import Map from '../img/map2.jpg'
import Button from 'react-bootstrap/Button';

export default function Prices() {
  return (
    <div className="page prices">
      <h1>Cennik</h1>
      <p>
        Polecamy trasy <b>Bursztynowy szlak - Wielki łuk Warty.</b><br />
        Odcinki łatwe od 1 do 7 godz.<br />
        <li>Krzeczów - Osjaków (Długość: 16 km | Czas spływu: 3-4 h)</li>
        <li>Osjaków - Konopnica (Długość: 11 km | Czas spływu: 2 h)</li>
        <li>Konopnica - Burzenin (Długość: 15 km | Czas spływu: 3-4 h)</li>
        <li>Burzenin - Sieradz (Długość: 26 km | Czas spływu: 6 h)</li>
        <li>Możliwość ustalenia indywidualnych szlaków wodnych.</li><br/>
        Kajaki 1 / 2 / 3 osobowe z wyposażeniem - kapok, wodoodporny worek na telefon i dokumenty<br />
        Koszt:<br />
        kajak jedynka = 70zł<br />
        dwójka = 100zł<br />
        Rodzinny 2+1 = 120zł <br />
        + koszt transportu.<br/><br/>
        Wszystkie dodatkowe informacje pod telefonem:<br/>
        <Button variant="primary" className="button" onClick={() => document.location.href = `tel:603161393`}><i className="bi bi-telephone-fill"></i> 603 161 393</Button>
      </p>
      <div className='map-container'>
        <img src={Map} className='map' alt='' />
        <MapPoint left="47.5%" top="4%">Sieradz</MapPoint>
        <MapPoint left="58%" top="32%">Burzenin</MapPoint>
        <MapPoint left="57%" top="55%">Konopnica</MapPoint>
        <MapPoint left="53%" top="70%">Osjaków</MapPoint>
        <MapPoint left="50.5%" top="92%">Krzeczów</MapPoint>
      </div>
    </div>
  );
}

function MapPoint(p) {
  return (
    <i className="bi bi-geo-alt-fill point" style={{ top: `calc(${p.top} - 35px)`, left: `calc(${p.left} - 15px)` }}>
      <span className='point-name'>{p.children}</span>
    </i>
  );
}
